import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { DialogService } from './dialog/dialog.service';
import { MenuStateService } from './menu-state.service';
import { tap, mergeMap, map, delay } from 'rxjs/operators';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LjpHttpService {
  // confirm$: Observable<boolean>;
  // baseUrl = 'http://localhost:9000';
  baseUrl = '';

  constructor(public http: HttpClient, public ds: DialogService, public menuState: MenuStateService, private router: Router) {
  }

  withBaseUrl(service: string, endpoint: string, id?: string): string {
    return `${this.baseUrl}/api/${service}${endpoint}${id ? `/${id}` : ''}`;
  }

  getList(url: string, name: string): Observable<[any]> {
    this.menuState.pushLoading(true);
    return this.http.get(url).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.getErrorHandler(name)), // then handle the error
      tap(() => {
        this.menuState.pushLoading(false);
      })
    ) as Observable<[any]>;
  }

  getItem(url: string, name: string): Observable<any> {
    this.menuState.pushLoading(true);
    return this.http.get(url).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.getErrorHandler(name)), // then handle the error
      tap(() => {
        this.menuState.pushMessage({ message: `Hämtade: ${name}`, action: 'Lyckades' });
        this.menuState.pushLoading(false);
      })
    ) as Observable<any>;
  }

  save(url: string, item: any, name: string): Observable<any> {
    if (item._id) {
      return this.updateItem(url, item, name);
    } else {
      return this.createItem(url, item, name);
    }
  }

  private updateItem(url: string, item: any, name: string): Observable<any> {
    // this.menuState.pushLoading(true);
    return this.http.put(url, item).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.getErrorHandler(name)), // then handle the error
      tap(() => {
        this.menuState.pushMessage({ message: `Uppdatering av: ${name}`, action: 'Lyckades' });
        // this.menuState.pushLoading(false);
      })
    ) as Observable<any>;
  }

  public put(url: string, item: any, name: string): Observable<any> {
    // this.menuState.pushLoading(true);
    return this.http.put(url, item).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.getErrorHandler(name)), // then handle the error
      tap(() => {
        this.menuState.pushMessage({ message: `Uppdatering av: ${name}`, action: 'Lyckades' });
        // this.menuState.pushLoading(false);
      })
    ) as Observable<any>;
  }


  private createItem(url: string, item: any, name: string): Observable<any> {
    this.menuState.pushLoading(true);
    return this.http.post(url, item).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.getErrorHandler(name)), // then handle the error
      tap(() => {
        this.menuState.pushMessage({ message: `Skapade: ${name}`, action: 'Lyckades' });
        this.menuState.pushLoading(false);
      })
    ) as Observable<any>;
  }

  async removeItem(url: string, name: string): Promise<boolean> {
    const result = await this.ds.confirm(
      { content: `Är du säker på att du vill radera <b>${name}</b>?`, close: 'Avbryt', ok: 'RADERA' }
    ).toPromise();

    if (result) {
      this.menuState.pushLoading(true);
      try {
        await this.http.delete(url)
          .pipe(
            retry(3), // retry a failed request up to 3 times
            catchError(this.getErrorHandler(name)), // then handle the error
          ).toPromise();
        this.menuState.pushMessage({ message: `Radering av ${name}`, action: 'Lyckades' });
        this.menuState.pushLoading(false);
        return true;
      } catch (err) {
        console.log(err);
        this.menuState.pushMessage({ message: `Radering av ${name}`, action: 'Misslyckades' });
      }
      this.menuState.pushLoading(false);
      return false;
    } else {
      this.menuState.pushMessage({ message: `Radering av ${name}`, action: 'Avbröts' });
      return false;
    }
  }

  private getErrorHandler(name: string) {
    const menuState = this.menuState;
    const router = this.router;
    return function handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
        menuState.pushMessage({ message: `Ett nät- eller clientfel inträffade vid behandling av: ${name}`, action: 'Fel', duration: 4000 });
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
        menuState.pushMessage({
          message: `Ett server fel inträffade vid behandling av: ${name} (${error.status})`,
          action: 'Fel',
          duration: 4000 });
        if (error.status === 401) {
            router.navigateByUrl('/login');
        }
      }
      // Return an observable with a user-facing error message.
      menuState.pushLoading(false);
      return throwError('Something bad happened; please try again later.');
    };
  }
}
