import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum MenuID {
  pub,
  pubs,
  org,
  orgs,
}

export enum MenuItemActionNotYet {
  external,
  http,
  info,
};

export enum MenuAction {
  newArticle = 'newArticle',
  uploadArticle = 'uploadArticle',
  upload = 'upload',
  sortAlpha = 'sortAlpha',
  enumerate = 'enumerate',
  updateList = 'updateList',
  importTemplate = 'importTemplate',
  external = 'external',
  archive = 'archive',
  adds = 'adds',
  divider = 'divider',
  showTodoHelp = 'showTodoHelp',
}

export type MenuItem = {
  id?: MenuID
  title: string;
  action: string;
  toolbar?: boolean;
  url?: string;
  tooltip?: string;
  icon?: string;
  role?: number;
  auth?: boolean;
  subTitle?: string;
  subMenu?: MenuItem[];
  func?: any;
  data?: any;
  image?: string;
  isControlItem?: boolean;
  color?: string;
};

export type MessageItem = {
  message: string;
  action: string;
  duration?: number;
};

export enum MenuIcon {
  Checked = 'check_mark',
  Unchecked = 'check_box_outline_blank',
  Failed = 'cancel',
  Warning = 'error_outline',
  Napp = 'close',
  Help = 'help_outline',
}

@Injectable({
  providedIn: 'root'
})
export class MenuStateService {

  constructor() { }

  // Observable string sources
  private contextMenuChangeSource = new Subject<MenuItem[]>();
  private navMenuChangeSource = new Subject<MenuItem[]>();
  private menuActionSource = new Subject<MenuItem>();
  private toolbarInfoSource = new Subject<MenuItem[]>();
  private messageSource = new Subject<MessageItem>();
  private loadingSource = new Subject<boolean>();
  private showMenuSource = new Subject<boolean>();
  private showUserMenuSource = new Subject<boolean>();
  private showToolbarSource = new Subject<boolean>();
  private selectedThemeSource = new Subject<string>();
  private todoMenuSource = new Subject<MenuItem[]>();

  // Observable string streams
  contextMenuChange$ = this.contextMenuChangeSource.asObservable();
  navMenuChange$ = this.navMenuChangeSource.asObservable();
  menuAction$ = this.menuActionSource.asObservable();
  toolbarInfo$ = this.toolbarInfoSource.asObservable();
  message$ = this.messageSource.asObservable();
  loading$ = this.loadingSource.asObservable();
  showMenu$ = this.showMenuSource.asObservable();
  showUserMenu$ = this.showUserMenuSource.asObservable();
  showToolbar$ = this.showToolbarSource.asObservable();
  selectedTheme$ = this.selectedThemeSource.asObservable();
  todoMenuChange$ = this.todoMenuSource.asObservable();

  loadingCounter = 0;

  // Service message commands
  pushContextMenuChange(menu: MenuItem[]): void {
    this.contextMenuChangeSource.next(menu);
  }

  pushNavMenuChange(menu: MenuItem[]): void {
    this.navMenuChangeSource.next(menu);
  }

  pushMenuAction(item: MenuItem): void {
    this.menuActionSource.next(item);
  }

  pushToolbarInfo(info: MenuItem[]): void {
    this.toolbarInfoSource.next(info);
  }

  pushTodoMenu(todo: MenuItem[]): void {
    this.todoMenuSource.next(todo);
  }

  pushMessage(item: MessageItem): void {
    this.messageSource.next(item);
  }

  pushLoading(isLoading: boolean): void {
    if (isLoading) {
      this.loadingCounter++;
    } else {
      if (this.loadingCounter > 0) {
        this.loadingCounter--;
      }
    }
    //console.log('this.loadingCounter', this.loadingCounter);
    this.loadingSource.next(this.loadingCounter > 0);
  }

  pushShowMenu(show: boolean): void {
    this.showMenuSource.next(show);
  }

  pushShowUserMenu(show: boolean): void {
    console.log('set user manu', show);
    this.showUserMenuSource.next(show);
  }

  pushShowToolbar(show: boolean): void {
    this.showToolbarSource.next(show);
  }

  pushTheme(theme: string): void {
    this.selectedThemeSource.next(theme);
  }
}
